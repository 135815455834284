import { StaticImage } from "gatsby-plugin-image";
import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";

const CeeClearLanding = () => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 2; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  return (
    <main>
      <section className="py-24">
        <div className="xl:container mx-auto px-6 grid lg:grid-cols-2 items-center gap-x-12 gap-y-12">
          <div className="flex h-full">
            <StaticImage src="../../images/Sourci_ 455-975w.jpg" 
              alt=""
              placeholder="blurred"
              className="rounded-3xl" />
          </div>
          <div>
            <p className="font-black text-3xl lg:text-4xl">
            Started just over 4 years ago in 2017 by founders Giovanni Pino and Ellie Vaisman, Sourci imported over $100m of consumer goods in the last 2 years alone. Here's how we work with CEE CLEAR to manufacture their expanding accessories range and evolve their product range and grow their brand.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              CEE CLEAR.<br />
              Changing The<br />
              Accessory Game.<br />
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              CEE CLEAR was created for anyone who has ever overpacked their beauty products into daggy, single-use zip lock bags, wasted precious time digging for their keys or wallet or wanted something transparent, functional and chic to hold all of their stuff.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              CEE CLEAR Co-Founders Kat Moses and Morgan Tait struggled to find a bag that could solve these issues they were experiencing. The two go-getters set out to create their own range of PVC transparent accessories ranging from tote bags to cosmetic cases.
            </p>
            <p className="font-bold text-xl lg:text-2xl">CEE how we made amazing happen for CEE CLEAR.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/58a09a97-fbb6-4035-b4e9-cee53d3428e5_fthftyjifygkm.png"
                alt=""
                className="object-cover min-w-[100%]"
                placeholder="blurred" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-3 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>300+</span>
            </p>
            <p className="font-black text-xl">HOURS OF WORK</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>4</span>
            </p>
            <p className="font-black text-xl">PRODUCT RANGE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>$60,000</span>
            </p>
            <p className="font-black text-xl">ORDER VALUE</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/7a86180d-24ad-46f3-8cba-32bfbbbee601_KMHKJHJK-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
            A New Beginning.
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              As well-known marketing and branding gurus, Kat and Morgan reached out to Sourci to create and manage their supply chain so that they could spend their time focusing on the sales and marketing aspects of the business in the lead up to their launch.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              The Sourci team got to work to find a manufacturer in China that could grow and scale with the brand without compromising the quality of the product.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With hopes to expand the product range and enhance the availability in the future, it was important that we partnered with a manufacturer who was able to meet the potential demand in years to come.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              We facilitated the sampling and resampling of the accessories to ensure that Kat and Morgan were 100% happy with the quality of their products before launch.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-4xl lg:text-5xl mb-12">
            See if we can help with your product
          </h3>
          <a href="https://sourci.leadshook.io/survey/Snar5vhx9dVnFUtuv0dYztx5DEfYI2Pma7csY19s"
            rel="noreferrer noopener"
            target="_blank"
            className="text-white relative rounded-xl justify-center px-12 py-4 whitespace-nowrap bg-sourci-teal hover:bg-sourci-teal-100 text-xl transition-colors">
            Get Started {'>'}
          </a>
        </div>
      </section>

    </main>
  )
}

export default CeeClearLanding;

export function Head() {
  return (
    <Seo title="CEE CLEAR | Sourci"
      description="CEE CLEAR Co-Founders Kat Moses and Morgan Tait struggled to find a bag that could solve these issues they were experiencing. The two go-getters set out to create their own range of PVC transparent accessories ranging from tote bags to cosmetic cases."
      canonical="/lp/ceeclear" />
  )
}